import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './FuelPromoTerms.module.css';

const FuelPromoTerms = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p>Trailer owners who already have a trailer(s) on www.localtrailerhire.com.au are not eligible for this offer.</p>
      <p>A trailer owner can only receive one $50 fuel card. This is payable once the first hire has been completed.</p>
      <p>The fuel card will be emailed to recipients once their first hire has been completed.</p>
      <p>The promotion is only open to Australian residents.</p>
      <p>The promotion ends January 31, 2026. Trailers must be listed by January 31, 2026 but first hire can happen after this date and still be valid.</p>
      <p>We retain the absolute right to refuse to pay or cancel a bonus fuel card payment without notice to you. The reasons for us to refuse or cancel a payment may include, but are not limited to, if:</p>
      <ul>
        <li>we believe that your access to localtrailerhire.com.au is or may be fraudulent</li>
        <li>or we believe that you have threatened the security of localtrailerhire.com.au</li>
        <li>or we believe you have breached any of our terms</li>
        <li>or we believe you are acting in bad faith</li>
        <li>or we have locked or closed your account.</li>
      </ul>
      <p>We also retain the absolute right to stop the offer at any time and to change the conditions or incentive of the offer.</p>
    </div>
  );
};

FuelPromoTerms.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

FuelPromoTerms.propTypes = {
  rootClassName: string,
  className: string,
};

export default FuelPromoTerms;
